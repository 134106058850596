/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell as MuiTableCell, TableContainer, TableHead, TableRow, Paper,
  IconButton, Tooltip, Box, Button,
} from '@mui/material/';
import { styled } from '@mui/material/styles';
import {
  Delete as DeleteIcon, Add as AddIcon,
} from '@mui/icons-material';
import MoneyInput from '../../atoms/moneyInput/MoneyInput';
import TextArea from '../../atoms/textArea/TextArea';

const TableCell = styled(MuiTableCell)(() => ({
  padding: 0,
}));

const HeadTableCell = styled(MuiTableCell)(() => ({
  background: '#F3F3F3',
  padding: 8,
}));

export default function DetailInputTable(props) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const initialRow = {
      displayOrder: '', summary: '', quantity: '', unitPrice: '', totalPrice: '', zeiKubun: '10',
    };
    setRows([...props.datas, initialRow]);
  }, [props.datas]);

  const handleAddRow = () => {
    const newRow = {
      displayOrder: '', summary: '', quantity: '', unitPrice: '', totalPrice: '', zeiKubun: '10',
    };
    setRows([...rows, newRow]);
  };

  const handleDeleteRow = (index) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
  };

  const handleInputChange = (index, field, value) => {
    const newRows = [...rows];
    // オブジェクトのコピーを作成
    const updatedRow = { ...newRows[index], [field]: value };

    if (field === 'quantity' || field === 'unitPrice') {
      const quantity = parseFloat(String(updatedRow.quantity).replace(/,/g, '')) || 0;
      const unitPrice = parseFloat(String(updatedRow.unitPrice).replace(/,/g, '')) || 0;
      const totalPrice = quantity * unitPrice;
      updatedRow.totalPrice = totalPrice;
    }

    // コピーを更新
    newRows[index] = updatedRow;
    setRows(newRows);
  };

  const handleSave = () => {
    const filteredRows = rows.filter(
      (row) => row.displayOrder || row.summary || row.quantity || row.unitPrice || row.totalPrice,
    );
    props.onSave(filteredRows);
  };

  return (
    <Box>
      <Box mb={1}>
        <Button size="small" variant="contained" onClick={handleSave}>保存</Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <HeadTableCell style={{ width: '10%' }}>表示順</HeadTableCell>
              <HeadTableCell>摘要</HeadTableCell>
              <HeadTableCell>数量</HeadTableCell>
              <HeadTableCell>単価</HeadTableCell>
              {props.isTax && (<HeadTableCell style={{ width: '10%' }}>税</HeadTableCell>)}
              <HeadTableCell>合計金額</HeadTableCell>
              <HeadTableCell><Tooltip title="行追加"><IconButton size="small" onClick={handleAddRow}><AddIcon /></IconButton></Tooltip></HeadTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>
                  <MoneyInput
                    fullWidth
                    value={row.displayOrder}
                    onChange={(e) => handleInputChange(index, 'displayOrder', e.target.value)}
                  />
                </TableCell>
                <TableCell style={{ display: 'flex' }}>
                  <TextArea
                    minRows={1}
                    value={row.summary}
                    onChange={(e) => handleInputChange(index, 'summary', e.target.value)}
                  />
                </TableCell>
                <TableCell>
                  <MoneyInput
                    fullWidth
                    value={row.quantity}
                    onChange={(e) => handleInputChange(index, 'quantity', e.target.value)}
                  />
                </TableCell>
                <TableCell>
                  <MoneyInput
                    fullWidth
                    value={row.unitPrice}
                    onChange={(e) => handleInputChange(index, 'unitPrice', e.target.value)}
                  />
                </TableCell>
                {props.isTax && (
                  <TableCell>
                    <MoneyInput
                      fullWidth
                      value={row.zeiKubun}
                      onChange={(e) => handleInputChange(index, 'zeiKubun', e.target.value)}
                    />
                  </TableCell>
                )}
                <TableCell align="right">{row.totalPrice ? row.totalPrice.toLocaleString() : 0}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  <Tooltip title="削除">
                    <IconButton size="small" onClick={() => handleDeleteRow(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
